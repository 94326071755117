var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"left-navigator"},[_vm._m(0),_c('div',{staticClass:"navigator-items-container"},_vm._l((_vm.$router.getRoutes()),function(route,index){return _c('div',{key:index,class:[
                'navigator-item-container',
                _vm.$route.matched.map(function (ref) {
                            var path = ref.path;

                            return path;
}).includes(route.path) ? 'active' : '',
                !_vm.currentProfile ? 'un_click' : '' ],on:{"click":function($event){return _vm.onPageNavigate(route)}}},[_c('div',{staticClass:"navigator-icon-container"},[_c('img',{staticClass:"navigator-icon",attrs:{"src":route.meta.icon}})]),_c('div',{staticClass:"navigator-text-container"},[_c('div',[_vm._v(_vm._s(_vm.$t(route.meta.title)))])])])}),0),_c('div',{staticClass:"network-container"},[_c('PeerSelector')],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logo-container"},[_c('img',{staticClass:"logo-img",attrs:{"src":require("@/views/resources/kadesh-logo.png")}})])}]

export { render, staticRenderFns }